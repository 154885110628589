.topToolbar button {
  margin-right: 1rem;
}

.largeModal {
  width: 90%;
  max-width: none;
}

.width2rem {
  width: 2rem;
}
